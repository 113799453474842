@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");
* {
  font-family: "Plus Jakarta Sans";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  border: none;
  text-transform: capitalize;
  transition: 0.2s linear;
  list-style: none;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
  scroll-behavior: smooth;
  background: #f7f7f7;
}

section {
  padding: 2rem 9%;
}

footer {
  padding: 2rem 9%;
}

.btn {
  display: inline-block;
  color: #130f40;
  background: #f2c94c;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border-radius: 0.3rem;
  font-size: 1.7rem;
  cursor: pointer;
}
.btn:hover {
  background: #ffe900;
}

.title {
  color: #130f40;
  font-size: 3rem;
  padding: 2rem 0;
}

.logo {
  font-size: 4rem;
  max-width: 7rem;
  cursor: pointer;
}

.topBanner {
  padding: 0;
  height: 40vh;
  position: relative;
}
.topBanner img {
  height: 40vh;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.topBanner .content {
  position: absolute;
  top: 10vh;
  color: #fff;
  padding: 2rem 9%;
  z-index: 1;
}
.topBanner .content h2 {
  padding: 2rem 0;
  font-size: 4rem;
}
.topBanner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40vh;
  background: rgba(0, 0, 0, 0.7);
}

.map {
  width: 100%;
  height: 50rem;
}

header {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 9%;
  background: #fff;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
}
header .nav ul {
  display: flex;
}
header .nav ul li .links {
  font-size: 1.5rem;
  margin: 0 1rem;
  color: #666;
}
header .nav ul li .links:hover, header .nav ul li .links.active {
  color: #f2c94c;
  background: #130f40;
  padding: 1rem 2rem;
}
header .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
header .icons button {
  color: #130f40;
}
header .icons button #menu-bars {
  display: none;
}
header .icons svg {
  padding: 1rem;
  height: 4rem;
  width: 4rem;
  background: #f7f7f7;
  border-radius: 0.5rem;
  color: #130f40;
}
header .icons svg:hover {
  color: #fff;
  background: #f2c94c;
}

.hero {
  background: url("../images/hero2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 80vh;
  position: relative;
}
.hero::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.hero .content {
  transform: translateY(20rem);
  width: 50%;
}
.hero .content h3 {
  color: #fff;
  font-size: 4rem;
  font-weight: 900;
  text-shadow: 0.2rem 0.2rem black;
  line-height: 1.5;
  letter-spacing: 0.1rem;
}

.mission {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin: 10rem 0;
  flex-wrap: wrap;
}
.mission .content {
  flex: 1 1 30rem;
}
.mission .content p {
  color: #666;
  letter-spacing: 0.1rem;
  margin: 0.5rem 0;
  text-align: justify;
  line-height: 1.5;
  font-size: 1.4rem;
  text-transform: none;
}
.mission .image {
  flex: 1 1 30rem;
}
.mission .image img {
  width: 100%;
}

.frgBackground {
  background: #fcedc6;
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.frgBackground .content {
  flex: 1 1 40rem;
}
.frgBackground .content .title {
  font-size: 4rem;
}
.frgBackground .icons {
  flex: 1 1 40rem;
  color: #130f40;
}
.frgBackground .icons .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1.5rem;
  overflow: hidden;
}
.frgBackground .icons .container .box {
  height: 15rem;
  width: 15rem;
  border: 2px solid #130f40;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  vertical-align: middle;
  font-size: 80rem;
  padding: 2rem;
}
.frgBackground .icons .container .box .content {
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
}

.recent .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  gap: 1.5rem;
  padding: 2rem 0;
}
.recent .container .box:hover {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
  transition: 0.4s linear;
  cursor: pointer;
}
.recent .container .box .image {
  height: 30rem;
}
.recent .container .box .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.recent .container .box .content {
  padding: 2rem;
  margin-top: 2rem;
}
.recent .container .box .content .time {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1.2rem;
  color: #666;
}
.recent .container .box .content .title {
  padding: 0.5rem 0;
  font-size: 1.7rem;
}
.recent .container .box .content article {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.1rem;
  color: #666;
  padding-bottom: 1rem;
  text-align: justify;
  text-transform: none;
}
.recent .container .box .content .link {
  color: #f2c94c;
  font-size: 1.2rem;
}
.recent .container .box .content .link:hover {
  color: #130f40;
}
.recent .button {
  text-align: center;
  padding: 2rem 0;
  margin: 6rem 0;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.BackgroundImgBanner {
  background: url("../images/BackgroundImgBanner.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  height: 50rem;
}
.BackgroundImgBanner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}
.BackgroundImgBanner h3 {
  color: #fff;
  font-size: 8rem;
  text-transform: uppercase;
  transform: translateY(35%);
  text-shadow: 0.2rem 0.2rem black;
  text-align: center;
  overflow: hidden;
}

.our-team .container,
.our-team-main .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  gap: 1.5rem;
  gap: 2.5rem;
}
.our-team .container .box,
.our-team-main .container .box {
  background: #fcedc6;
}
.our-team .container .box:hover,
.our-team-main .container .box:hover {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background: #f2c94c;
}
.our-team .container .box .image,
.our-team-main .container .box .image {
  height: 30rem;
}
.our-team .container .box .image img,
.our-team-main .container .box .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.our-team .container .box .content,
.our-team-main .container .box .content {
  padding: 2rem;
  text-align: center;
}
.our-team .container .box .content:hover p,
.our-team .container .box .content:hover h3,
.our-team-main .container .box .content:hover p,
.our-team-main .container .box .content:hover h3 {
  color: #fff;
}
.our-team .container .box .content p,
.our-team-main .container .box .content p {
  color: #666;
  font-size: 1.5rem;
  padding-top: 1rem;
}
.our-team .container .box .content .title,
.our-team-main .container .box .content .title {
  padding: 0.5rem 0;
  font-size: 2.5rem;
}
.our-team .container .box .content article,
.our-team-main .container .box .content article {
  font-size: 1.2rem;
  color: rgb(0, 0, 0);
  font-weight: 500;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  text-transform: none;
}
.our-team .modal,
.our-team-main .modal {
  position: fixed;
  top: 0rem;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8470588235);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}
.our-team .modal .closeBtn,
.our-team-main .modal .closeBtn {
  color: #130f40;
  position: absolute;
  top: 17%;
  right: 11%;
  font-size: 2.5rem;
  cursor: pointer;
}
.our-team .modal .modal-container,
.our-team-main .modal .modal-container {
  background: #fefdfc;
  display: flex;
  gap: 2rem;
  padding: 5rem 2rem 1rem;
  border-radius: 0.5rem;
  height: 70vh;
  overflow: auto;
}
.our-team .modal .modal-container .img,
.our-team-main .modal .modal-container .img {
  flex: 1 1 20rem;
  height: 30rem;
  border-radius: 1rem;
  overflow: hidden;
}
.our-team .modal .modal-container .img img,
.our-team-main .modal .modal-container .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.our-team .modal .modal-container .content,
.our-team-main .modal .modal-container .content {
  flex: 3 1 30rem;
  height: 30rem;
  padding: 0 2rem;
}
.our-team .modal .modal-container .content .name,
.our-team-main .modal .modal-container .content .name {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.our-team .modal .modal-container .content p,
.our-team-main .modal .modal-container .content p {
  text-transform: none;
  margin-top: 2rem;
  padding-bottom: 2rem;
  color: #000;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  line-height: 1.6;
}
.our-team .button,
.our-team-main .button {
  text-align: center;
  padding: 2rem 0;
  margin: 6rem 0;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

footer {
  background: #f2c94c;
}
footer .top {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  gap: 1.5rem;
}
footer .top .box {
  color: #666;
  font-size: 1.5rem;
  font-weight: 600;
}
footer .top .box .logo-socials {
  text-align: center;
}
footer .top .box .logo-socials .socials {
  font-size: 4rem;
}
footer .top .box .logo-socials .socials svg {
  margin: 1.2rem 0.5rem;
  color: #130f40;
}
footer .top .box .logo-socials .socials svg:hover {
  transform: translateY(-1rem);
  transition: 0.4s linear;
}
footer .top .box .logo-socials .btn {
  color: #f2c94c;
  background: #130f40;
  margin: 0;
}
footer .top .box .logo-socials .btn:hover {
  transform: translateY(-1rem);
  transition: 0.4s linear;
}
footer .top .box .links .nav ul li {
  padding: 1rem 0;
}
footer .top .box .links .nav ul li .links {
  font-size: 1.5rem;
  color: #666;
}
footer .top .box .links .nav ul li .links:hover {
  color: #fff;
}
footer .top .box .address,
footer .top .box .phone,
footer .top .box .email {
  padding: 1rem 0;
}
footer .top .box .address:hover,
footer .top .box .phone:hover,
footer .top .box .email:hover {
  color: #fff;
  cursor: pointer;
}
footer .top .box .mail {
  text-transform: lowercase;
}
footer .copyright {
  border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #666;
  text-align: center;
  padding: 2rem 0;
  text-transform: none;
}

.about-us h3 {
  font-size: 2rem;
  line-height: 1;
  margin-top: 2rem;
}
.about-us article {
  font-size: 1.3rem;
  line-height: 1.6;
  letter-spacing: 0.1rem;
  padding: 0.2rem 0;
  color: rgb(0, 0, 0);
  margin-top: 1rem;
  font-weight: 600;
  text-transform: none;
}

.about-us-card-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.about-us-card {
  background: #fcedc6;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
  padding: 2rem 3rem;
}
.about-us-card ul li {
  list-style-type: square;
  font-size: 1.35rem;
  line-height: 1.6;
  letter-spacing: 0.1rem;
  padding: 0.2rem 0;
  color: rgb(0, 0, 0);
  font-weight: 600;
  text-transform: none;
}
.about-us-card h3 {
  font-size: 2rem;
}
.about-us-card article {
  font-size: 1.35rem;
  line-height: 1.6;
  letter-spacing: 0.1rem;
  padding: 0.2rem 0;
  color: rgb(0, 0, 0);
  margin-top: 1rem;
  font-weight: 400;
  text-transform: none;
}

.research-page .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  gap: 1.5rem;
  padding: 2rem 0;
  margin-bottom: 4rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}
.research-page .container .box:hover {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
  transition: 0.4s linear;
  cursor: pointer;
}
.research-page .container .box .image {
  height: 30rem;
}
.research-page .container .box .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.research-page .container .box .content {
  padding: 2rem;
  margin-top: 2rem;
}
.research-page .container .box .content .time {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1.2rem;
  color: #666;
}
.research-page .container .box .content .title {
  padding: 0.5rem 0;
  font-size: 1.7rem;
}
.research-page .container .box .content article {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.1rem;
  color: #666;
  padding-bottom: 1rem;
  text-align: justify;
  text-transform: none;
}
.research-page .container .box .content .link {
  color: #f2c94c;
  font-size: 1.2rem;
}
.research-page .container .box .content .link:hover {
  color: #130f40;
}

.readMore .img {
  max-width: 80%;
  margin: auto;
}
.readMore .img img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.readMore h1 {
  margin: 1rem 0;
  font-size: 2.5rem;
  line-height: 1.6;
  text-transform: uppercase;
}
.readMore .summary {
  margin: 1rem 0;
}
.readMore article {
  margin: 2rem 0;
  font-size: 1.5rem;
  line-height: 1.6;
  text-transform: none;
  text-transform: none;
}

.contact-form form {
  margin: 2rem auto;
  width: 50%;
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
  position: relative;
}
.contact-form form p {
  padding: 1rem 0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #130f40;
  letter-spacing: 0.1rem;
}
.contact-form form .inputField {
  padding: 0.2rem 0;
}
.contact-form form .inputField input,
.contact-form form .inputField textarea {
  background: #f7f7f7;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  color: #130f40;
}
.contact-form form .inputField textarea {
  height: 20rem;
  resize: none;
}
.contact-form form .alert {
  text-align: center;
  display: none;
}
.contact-form form .alert small {
  font-size: 1.7rem;
  color: rgb(39, 172, 39);
  background: #f7f7f7;
  padding: 1rem 2rem;
  display: inline-block;
  margin: 1rem 0;
  border-radius: 0.5rem;
}
.contact-form form div {
  text-align: center;
}

.donate {
  background: url("../images/donate2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: local;
  width: 100%;
  height: 100vh;
  position: relative;
}
.donate::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.donate .content {
  transform: translateY(20rem);
  width: 70%;
}
.donate .content h3,
.donate .content h1 {
  color: #fff;
  font-size: 5rem;
  font-weight: 900;
  text-shadow: 0.2rem 0.2rem black;
  line-height: 1.5;
  letter-spacing: 0.1rem;
}

@media all and (max-width: 991px) {
  html {
    font-size: 55%;
  }
  section {
    padding: 2rem;
  }
  header {
    padding: 2rem;
  }
  footer {
    padding: 2rem;
  }
}
@media all and (max-width: 768px) {
  html {
    font-size: 50%;
  }
  header .nav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
    background: #f2c94c;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  header .nav.active {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  header .nav Ul {
    flex-direction: column;
  }
  header .nav Ul li {
    margin: 1.5rem 0;
  }
  header .nav Ul li .links {
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
    font-size: 2rem;
  }
  header .nav Ul li .links:hover {
    color: #fff;
  }
  header .icons button #menu-bars {
    display: inline-block;
  }
  header .icons .social-icons {
    display: none;
  }
  header .icons .btn {
    display: none;
  }
  .hero .content {
    width: auto;
  }
  .BackgroundImgBanner h3 {
    font-size: 4rem;
    transform: translateY(90%);
  }
  .contact-form form {
    width: 100%;
  }
  .our-team-main .modal .closeBtn,
  .our-team .modal .closeBtn {
    top: 2%;
    right: 8%;
  }
  .our-team-main .modal .modal-container,
  .our-team .modal .modal-container {
    height: 100vh;
    width: 100vw;
  }
}
@media all and (max-width: 450px) {
  html {
    font-size: 50%;
  }
  .frgBackground .icons .container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
  }
}/*# sourceMappingURL=index.css.map */