$green: #27ae60;
$black: #130f40;
$white: #fff;
$light-color: #666;
$light-bg: #f7f7f7;
$light-yellow: #fcedc6;
$yellow: #f2c94c;
$bright-yellow: #ffe900;
$bg: #fefdfc;
$border: 0.1rem solid rgba(0, 0, 0, 0.2);
$box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
$box-shadow1: 0 1rem 2rem rgba(0, 0, 0, 0.5);
$dark-black: rgb(0, 0, 0);
