@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

// import partials
@import "variables";
@import "mixins";

* {
  font-family: "Plus Jakarta Sans";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  border: none;
  text-transform: capitalize;
  transition: 0.2s linear;
  list-style: none;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
  scroll-behavior: smooth;
  background: $light-bg;
}

// global styles

section {
  padding: 2rem 9%;
}

footer {
  padding: 2rem 9%;
}

.btn {
  display: inline-block;
  color: $black;
  background: $yellow;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border-radius: 0.3rem;
  font-size: 1.7rem;
  cursor: pointer;

  &:hover {
    background: $bright-yellow;
    // font-size: 1.8rem;
    // font-weight: 600;
  }
}

.title {
  color: $black;
  font-size: 3rem;
  padding: 2rem 0;
}

.logo {
  font-size: 4rem;
  // position: relative;
  max-width: 7rem;
  // height: 4rem;
  // width: 4rem;
  // filter: invert(2);
  cursor: pointer;
}

.topBanner {
  padding: 0;
  height: 40vh;
  position: relative;

  img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
  }
  .content {
    position: absolute;
    // max-width: 70%;
    top: 10vh;
    color: $white;
    padding: 2rem 9%;
    z-index: 1;
    h2 {
      padding: 2rem 0;
      font-size: 4rem;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 40vh;
    background: rgba(0, 0, 0, 0.7);
  }
}

.map {
  width: 100%;
  height: 50rem;
}

// global styles

// header styles starts
header {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 9%;
  background: $white;
  box-shadow: $box-shadow;

  .nav {
    ul {
      display: flex;
      li {
        .links {
          font-size: 1.5rem;
          margin: 0 1rem;
          color: $light-color;

          &:hover,
          &.active {
            color: $yellow;
            background: $black;
            padding: 1rem 2rem;
          }
        }
      }
    }
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    // font-size: 2rem;

    button {
      color: $black;
      #menu-bars {
        display: none;
      }
    }

    svg {
      padding: 1rem;
      height: 4rem;
      width: 4rem;
      background: $light-bg;
      border-radius: 0.5rem;
      color: $black;
      &:hover {
        color: $white;
        background: $yellow;
      }
    }
  }
}
// header styles ends

// hero starts

.hero {
  background: url("../images/hero2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 80vh;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .content {
    transform: translateY(20rem);
    width: 50%;
    h3 {
      color: $white;
      font-size: 4rem;
      font-weight: 900;
      text-shadow: 0.2rem 0.2rem black;
      line-height: 1.5;
      letter-spacing: 0.1rem;
    }
  }
}
// hero ends

// mission starts
.mission {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin: 10rem 0;
  flex-wrap: wrap;

  .content {
    flex: 1 1 30rem;
    p {
      color: $light-color;
      letter-spacing: 0.1rem;
      margin: 0.5rem 0;
      text-align: justify;
      line-height: 1.5;
      font-size: 1.4rem;
      text-transform: none;
    }
  }
  .image {
    flex: 1 1 30rem;

    img {
      width: 100%;
    }
  }
}

// mission ends

// foreground section starts
.frgBackground {
  background: $light-yellow;
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  // height: 50rem;
  padding-top: 10rem;
  padding-bottom: 10rem;

  .content {
    flex: 1 1 40rem;
    .title {
      font-size: 4rem;
    }
  }
  .icons {
    flex: 1 1 40rem;
    color: $black;
    // overflow: hidden;
    .container {
      // display: flex;
      // justify-content: space-around;
      // align-items: center;
      // flex: 1 1 20rem;
      // flex-wrap: wrap;
      // gap: 4rem;
      // font-size: 8rem;
      @include grid(15rem);
      overflow: hidden;

      .box {
        height: 15rem;
        width: 15rem;
        border: 2px solid $black;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        vertical-align: middle;
        font-size: 80rem;
        // border: 0.2rem solid $black;
        // border-radius: 50%;
        // text-align: center;
        // overflow: hidden;

        padding: 2rem;

        // svg {
        //   // font-size: 8rem;
        //   // border: 0.2rem solid $black;
        //   // border-radius: 50%;
        //   // padding: 1rem;
        // }

        .content {
          font-size: 1.5rem;
          // transform: translateX(3%);
          text-align: center;
          padding: 1rem;
        }
      }
    }
  }
}
// foreground section ends

// recent section starts
.recent {
  .container {
    @include grid(35rem);
    padding: 2rem 0;
    // overflow: hidden;
    .box {
      &:hover {
        box-shadow: $box-shadow1;
        transition: 0.4s linear;
        cursor: pointer;
      }

      .image {
        height: 30rem;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .content {
        padding: 2rem;
        margin-top: 2rem;
        .time {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          font-size: 1.2rem;
          color: $light-color;
        }
        .title {
          padding: 0.5rem 0;
          font-size: 1.7rem;
        }
        article {
          font-size: 1.4rem;
          line-height: 1.5;
          letter-spacing: 0.1rem;
          color: $light-color;
          padding-bottom: 1rem;
          text-align: justify;
          text-transform: none;
        }
        .link {
          color: $yellow;
          font-size: 1.2rem;
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
  .button {
    text-align: center;
    padding: 2rem 0;
    margin: 6rem 0;
    border-bottom: $border;
  }
}
// recent section ends

// BackgroundImgBanner section starts
.BackgroundImgBanner {
  background: url("../images/BackgroundImgBanner.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  height: 50rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
  }
  h3 {
    color: $white;
    font-size: 8rem;
    text-transform: uppercase;
    transform: translateY(35%);
    text-shadow: 0.2rem 0.2rem black;
    text-align: center;
    overflow: hidden;
  }
}
// BackgroundImgBanner section ends

// out team section starts
.our-team,
.our-team-main {
  .container {
    @include grid(35rem);
    gap: 2.5rem;

    .box {
      background: $light-yellow;

      &:hover {
        box-shadow: $box-shadow1;
        cursor: pointer;
        background: $yellow;
      }

      .image {
        height: 30rem;
        // overflow: hidden;
        // width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .content {
        padding: 2rem;
        text-align: center;
        &:hover {
          p,
          h3 {
            color: $white;
          }
        }
        p {
          color: $light-color;
          font-size: 1.5rem;
          padding-top: 1rem;
        }
        .title {
          padding: 0.5rem 0;
          font-size: 2.5rem;
        }
        article {
          font-size: 1.2rem;
          color: $dark-black;
          font-weight: 500;
          line-height: 1.8rem;
          letter-spacing: 0.1rem;
          text-transform: none;
        }
      }
    }
  }
  .modal {
    // position: relative;
    .closeBtn {
      color: $black;
      position: absolute;
      top: 17%;
      right: 11%;
      font-size: 2.5rem;
      cursor: pointer;
    }
    position: fixed;
    top: 0rem;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000d8;
    // background: red;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    .modal-container {
      background: $bg;
      display: flex;
      gap: 2rem;
      padding: 5rem 2rem 1rem;
      border-radius: 0.5rem;
      height: 70vh;
      overflow: auto;

      .img {
        flex: 1 1 20rem;
        height: 30rem;
        border-radius: 1rem;
        overflow: hidden;
        // height: 10rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .content {
        flex: 3 1 30rem;
        height: 30rem;
        padding: 0 2rem;
        // overflow-y: scroll;

        .name {
          font-weight: bold;
          font-size: 2rem;
          margin-bottom: 1rem;
        }
        p {
          text-transform: none;
          margin-top: 2rem;
          padding-bottom: 2rem;
          color: #000;
          font-size: 1.2rem;
          font-weight: 600;
          letter-spacing: 0.1rem;
          line-height: 1.6;
          // text-align: justify;
          // overflow-y: scroll;
        }
      }
    }
  }
  .button {
    text-align: center;
    padding: 2rem 0;
    margin: 6rem 0;
    border-bottom: $border;
  }
}
// out team section ends

// footer section starts
footer {
  background: $yellow;
  .top {
    @include grid(35rem);
    .box {
      color: $light-color;
      font-size: 1.5rem;
      font-weight: 600;

      .logo-socials {
        text-align: center;
        .socials {
          font-size: 4rem;
          svg {
            margin: 1.2rem 0.5rem;
            color: $black;
            &:hover {
              transform: translateY(-1rem);
              transition: 0.4s linear;
            }
          }
        }
        .btn {
          color: $yellow;
          background: $black;
          margin: 0;
          &:hover {
            transform: translateY(-1rem);
            transition: 0.4s linear;
          }
        }
      }

      .links {
        .nav {
          ul {
            li {
              padding: 1rem 0;
              .links {
                font-size: 1.5rem;
                // font-weight: bold;

                color: $light-color;
                &:hover {
                  color: $white;
                }
              }
            }
          }
        }
      }
      .address,
      .phone,
      .email {
        padding: 1rem 0;
        &:hover {
          color: $white;
          cursor: pointer;
        }
      }
      .mail {
        text-transform: lowercase;
      }
    }
  }
  .copyright {
    border-top: $border;
    margin-top: 1rem;
    font-size: 1.5rem;
    color: $light-color;
    text-align: center;
    padding: 2rem 0;
    text-transform: none;
  }
}
// footer section ends

// about-us section starts
.about-us {
  h3 {
    font-size: 2rem;
    line-height: 1;
    margin-top: 2rem;
  }
  article {
    font-size: 1.3rem;
    line-height: 1.6;
    letter-spacing: 0.1rem;
    padding: 0.2rem 0;
    color: $dark-black;
    margin-top: 1rem;
    font-weight: 600;
    text-transform: none;
  }
  // ol {
  //   li {
  //     list-style-type: disc;
  //     font-size: 1.2rem;
  //     line-height: 1.6;
  //     letter-spacing: 0.1rem;
  //     padding: 0.2rem 0;
  //     color: $dark-black;
  //     font-weight: 600;
  //   }
  // }
}

.about-us-card-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.about-us-card {
  background: $light-yellow;
  box-shadow: $box-shadow;
  padding: 2rem 3rem;
  // margin: 0.2rem 0;

  ul {
    // overflow-x: auto;
    li {
      list-style-type: square;
      font-size: 1.35rem;
      line-height: 1.6;
      letter-spacing: 0.1rem;
      padding: 0.2rem 0;
      color: $dark-black;
      font-weight: 600;
      text-transform: none;
    }
  }
  h3 {
    font-size: 2rem;
  }
  article {
    font-size: 1.35rem;
    line-height: 1.6;
    letter-spacing: 0.1rem;
    padding: 0.2rem 0;
    color: $dark-black;
    margin-top: 1rem;
    font-weight: 400;
    text-transform: none;
  }
}
// about-us section ends

// research-page starts
.research-page {
  .container {
    @include grid(35rem);
    padding: 2rem 0;
    margin-bottom: 4rem;
    border-bottom: $border;
    // overflow: hidden;
    .box {
      &:hover {
        box-shadow: $box-shadow1;
        transition: 0.4s linear;
        cursor: pointer;
      }

      .image {
        height: 30rem;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .content {
        padding: 2rem;
        margin-top: 2rem;
        .time {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          font-size: 1.2rem;
          color: $light-color;
        }
        .title {
          padding: 0.5rem 0;
          font-size: 1.7rem;
        }
        article {
          font-size: 1.4rem;
          line-height: 1.5;
          letter-spacing: 0.1rem;
          color: $light-color;
          padding-bottom: 1rem;
          text-align: justify;
          text-transform: none;
        }
        .link {
          color: $yellow;
          font-size: 1.2rem;
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
}
// research-page ends

// readMore style starts
.readMore {
  .img {
    // height: 10%;
    max-width: 80%;
    margin: auto;
    img {
      // margin: auto;
      object-fit: cover;
      height: 100%;
      width: 100%;
      // display: block;
    }
  }
  h1 {
    margin: 1rem 0;
    font-size: 2.5rem;
    line-height: 1.6;
    text-transform: uppercase;
  }
  .summary {
    margin: 1rem 0;
  }
  article {
    margin: 2rem 0;
    font-size: 1.5rem;
    line-height: 1.6;
    text-transform: none;
    text-transform: none;
  }
}
// readMore style ends

.contact-form {
  form {
    margin: 2rem auto;
    width: 50%;
    background: $white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: $box-shadow1;
    position: relative;

    p {
      padding: 1rem 0;
      text-align: center;
      font-size: 1.2rem;
      font-weight: bold;
      color: $black;
      letter-spacing: 0.1rem;
    }
    .inputField {
      padding: 0.2rem 0;
      input,
      textarea {
        background: $light-bg;
        width: 100%;
        padding: 1rem;
        border-radius: 0.5rem;
        color: $black;
      }
      textarea {
        height: 20rem;
        resize: none;
      }
    }
    .alert {
      text-align: center;
      display: none;
      small {
        font-size: 1.7rem;
        color: rgb(39, 172, 39);
        background: $light-bg;
        padding: 1rem 2rem;
        display: inline-block;
        margin: 1rem 0;
        border-radius: 0.5rem;
      }
    }
    div {
      text-align: center;
    }
  }
}

// donate starts
.donate {
  background: url("../images/donate2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: local;
  width: 100%;
  height: 100vh;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .content {
    transform: translateY(20rem);
    width: 70%;
    h3,
    h1 {
      color: $white;
      font-size: 5rem;
      font-weight: 900;
      text-shadow: 0.2rem 0.2rem black;
      line-height: 1.5;
      letter-spacing: 0.1rem;
    }
  }
}
// donate ends

// media queries
@media all and (max-width: 991px) {
  html {
    font-size: 55%;
  }
  section {
    padding: 2rem;
  }
  header {
    padding: 2rem;
  }
  footer {
    padding: 2rem;
  }
}

@media all and (max-width: 768px) {
  html {
    font-size: 50%;
  }

  header {
    .nav {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 1000;
      border-top: $border;
      border-bottom: $border;
      background: $yellow;
      // box-shadow: 0 1rem 1rem 100vh rgba(0, 0, 0, 0.9);
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

      &.active {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
      Ul {
        flex-direction: column;

        li {
          margin: 1.5rem 0;
          .links {
            border-bottom: $border;
            font-size: 2rem;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }

    .icons {
      button {
        #menu-bars {
          display: inline-block;
        }
      }
      .social-icons {
        display: none;
      }
      .btn {
        display: none;
      }
    }
  }

  .hero {
    .content {
      width: auto;
    }
  }

  .BackgroundImgBanner {
    h3 {
      font-size: 4rem;
      transform: translateY(90%);
    }
  }
  .contact-form {
    form {
      width: 100%;
    }
  }

  .our-team-main,
  .our-team {
    .modal {
      .closeBtn {
        top: 2%;
        right: 8%;
      }

      .modal-container {
        height: 100vh;
        width: 100vw;
      }
    }
  }
}

@media all and (max-width: 450px) {
  html {
    font-size: 50%;
  }
  .frgBackground {
    .icons {
      .container {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
